module.exports = [{
      plugin: require('/Users/maikmacs/code/gatsby/maikmacs/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/Users/maikmacs/code/gatsby/maikmacs/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-59035702-1"},
    },{
      plugin: require('/Users/maikmacs/code/gatsby/maikmacs/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
